<template>
  <div class="" style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn text class="mx-2" :to="'/warehouses/view/'+this.$route.params.warehouseId+'/rooms/view/'+this.$route.params.roomId">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>Container: {{this.cont.name}}</h1>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
            style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="isAllowed('container','u')" @click="isEditable = !isEditable">
            <span v-if="this.isEditable">Cancel</span>
            <span v-else>Edit</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-form :disabled="!isEditable">
        <v-row>
          <v-col>
            <v-text-field
                label="Name"
                outlined
                v-model="cont.name"
            ></v-text-field>
            <v-text-field
                label="Description"
                outlined
                v-model="cont.description"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col v-if="isEditable">
          <v-btn @click="updateCont">Save</v-btn>
          <v-btn v-if="isAllowed('container','d')" style="margin-left: 10px;" class="error" @click="deleteCont">Delete</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        editMode: false,
        cont: {},
        isEditable: false,
      }
    },
    async mounted(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/containers/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error
          this.cont = res.data.data
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed"])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async updateCont(){
        try {
          this.loader = true;
          let res = await axios.put(`${this.getEndpoint}/api/containers/${this.cont.id}`, this.cont)
          if(res.data.error) throw res.data.error

          if(res.data.success){
            this.snack("Container info Updated 🎉", "success");
            this.isEditable = false;
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async deleteCont(){
        try {
          this.loader = true
          let res = await axios.delete(`${this.getEndpoint}/api/containers/${this.cont.id}`)
          if(res.data.error) throw res.data.error

          this.snack("Container Deleted 🎉", "success");
          await this.$router.push({ path: `/warehouses/view/${this.$route.params.warehouseId}/rooms/view/${this.$route.params.roomId}`})
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false
        }
      }
    }
  }
</script>
