<template>
  <div class="" style="width: 100%;">
    <v-row>
      <v-col v-if="isAllowed('customer','c')" cols="12">
        <v-container>
          <v-row>
            <v-col class='d-flex flex-row align-center'>
              <h1>Add New Customer</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  label="Name"
                  style="margin-top:-15px;"
                  outlined
                  dense
                  v-model="newCustomer.name"
              />
              <v-text-field
                  label="Phone"
                  style="margin-top:-15px;"
                  outlined
                  dense
                  v-model="newCustomer.phone"
              />
              <v-text-field
                  label="Email"
                  style="margin-top:-15px;"
                  outlined
                  dense
                  v-model="newCustomer.email"
              />
              <v-btn style="margin-top:-15px;" color="success" @click="createCustomer" :disabled="(!(this.newCustomer.firstName && this.newCustomer.lastName) && !this.newCustomer.name) || !this.newCustomer.phone">Create</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col>
        <v-card outlined>
          <v-card-title class="text-h5">
            Customers
          </v-card-title>
          <v-card-text style="padding-top: 20px;">
            <v-text-field v-model="customerSearch.term" @change="searchCustomer" :loading="customerSearch.loader" label="Search Customers" outlined></v-text-field>
          </v-card-text>
          <v-card-text>
            <v-card outlined :key="i" v-for="(customer, i) in customerSearch.results" class="mb-2 d-flex flex-row align-center justify-space-between">
              <span class="d-flex flex-column justify-start" style="text-align: left; padding: 20px;">
                <h3>{{customer.name}}</h3>
                <p v-if="customer.email">{{customer.email}}</p>
                <p v-if="customer.phone">{{customer.phone}}</p>
              </span>
              <v-btn class="mr-2" x-small fab color="success" @click="()=>{$router.push({path: '/customers/view/'+customer.id})}"><v-icon>mdi-chevron-right</v-icon></v-btn>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          { text: 'Name', align: 'start', sortable: true, value: 'name' },
          { text: 'Phone', value: 'phone' },
          { text: 'Email', sortable: true, value: 'email' }
        ],
        editMode: false,
        customers: [],
        customerSearch: {
          term: '',
          loader: false,
          results: [],
          noSearchResults: false,
        },
        newCustomer: {
          name: '',
          phone: '',
          email: ''
        }
      }
    },
    async mounted(){
        await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', 'isAllowed'])
    },
    methods: {
      rowClick(cus){
        this.$router.push({ path: `/customers/view/${cus.id}`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async createCustomer(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/customers`, this.newCustomer)
          if(res.data.error) throw res.data.erro
          this.snack(`Customer Created 🎉`, "success");
          this.data={};
          this.newCustomer = {name: '', phone: '', email: ''}
          await this.getAllData();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      async getAllData(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/customers`)
          if(res.data.error) throw res.data.error
          this.customers = res.data.data
          console.log(this.customers)
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async searchCustomer(){
        try {
          if(this.customerSearch.term){
            this.customerSearch.loader = true
            let res = await axios.get(`${this.getEndpoint}/api/customers/search?val=${encodeURIComponent(this.customerSearch.term)}`);
            this.customerSearch.results = res.data.data
            if(this.customerSearch.results && this.customerSearch.results.length == 0) this.customerSearch.noSearchResults = true
            else this.customerSearch.noSearchResults = false
          }
        } catch (error) {
          console.log(error)
          this.snack(error)
        }finally{
          this.customerSearch.loader = false
        }
      },
    }
  }
</script>
